import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import './i18n';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  color: white;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 20px;
  direction: ${props => props.isRTL ? 'rtl' : 'ltr'};
`;

const Title = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  opacity: 0.9;
`;

const CountdownContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  justify-content: center;
`;

const CountdownItem = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem 2rem;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  min-width: 120px;
  
  .number {
    font-size: 2.5rem;
    font-weight: 600;
  }
  
  .label {
    font-size: 0.9rem;
    opacity: 0.8;
  }
`;

const LanguageButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 0.5rem 1rem;
  border: 2px solid white;
  border-radius: 25px;
  background: transparent;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background: white;
    color: #1e3c72;
  }
`;

function App() {
  const { t, i18n } = useTranslation();
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const toggleLanguage = () => {
    const newLang = i18n.language === 'ar' ? 'en' : 'ar';
    i18n.changeLanguage(newLang);
  };

  useEffect(() => {
    const targetDate = new Date('2025-01-01T00:00:00+03:00'); // Fixed date: January 1st, 2025

    const timer = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference < 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        clearInterval(timer);
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container isRTL={i18n.language === 'ar'}>
      <LanguageButton onClick={toggleLanguage}>
        {t('switchLanguage')}
      </LanguageButton>
      
      <Title>{t('title')}</Title>
      <Subtitle>{t('subtitle')}</Subtitle>
      
      <CountdownContainer>
        <CountdownItem>
          <div className="number">{timeLeft.days}</div>
          <div className="label">{t('days')}</div>
        </CountdownItem>
        <CountdownItem>
          <div className="number">{timeLeft.hours}</div>
          <div className="label">{t('hours')}</div>
        </CountdownItem>
        <CountdownItem>
          <div className="number">{timeLeft.minutes}</div>
          <div className="label">{t('minutes')}</div>
        </CountdownItem>
        <CountdownItem>
          <div className="number">{timeLeft.seconds}</div>
          <div className="label">{t('seconds')}</div>
        </CountdownItem>
      </CountdownContainer>
    </Container>
  );
}

export default App;
